import React, { useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { EditorComponent, EmailTemplate, OptionTypeBase } from "interfaces";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { Accordion } from "components/Accordion/Accordion";
import { useAuth } from "components/AuthProvider";
import { captureAdditionalFriendDetailsSettingsFields } from "components/CampaignConfigurationEditor/StepEditor/Editors/FriendJourney/CaptureAdditionalFriendDetailsEditor/utils";
import Dropdown from "components/Dropdown/Dropdown";
import ErrorAlert from "components/ErrorAlert";
import { Fieldset } from "components/Fieldset/Fieldset";
import Loader from "components/Loader";
import { Switch } from "components/Switch/Switch";
import { TextField } from "components/TextField/TextField";
import Transition from "components/Transition";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

import CaptureAdditionalFriendDetailsEditorWrapper from "../CaptureAdditionalFriendDetailsEditorWrapper/CaptureAdditionalFriendDetailsEditorWrapper";

const CaptureAdditionalFriendDetailsSettingsEditor: EditorComponent = ({
  flowId,
}) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!axios || !selectedMarket || !selectedCampaign)
    return <ErrorAlert message="Context is invalid" />;

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [configurableEmailTemplates, setConfigurableEmailTemplates] = useState<
    EmailTemplate[]
  >([]);

  const { getValues, control, setValue } = useFormContext();

  const settingsFields = captureAdditionalFriendDetailsSettingsFields();

  const [sendReminderEmail, setSendReminderEmail] = useState(
    !!getValues(settingsFields.reminderEmail.id)
  );

  const [firstReminderInterval, setFirstReminderInterval] = useState(
    !!getValues(settingsFields.firstReminderIntervals.id)
  );

  const [secondReminderInterval, setSecondReminderInterval] = useState(
    !!getValues(settingsFields.secondReminderIntervals.id)
  );

  const [thirdReminderInterval, setThirdReminderInterval] = useState(
    !!getValues(settingsFields.thirdReminderIntervals.id)
  );

  const configurableEmailTemplatesRequest = (
    source: CancelTokenSource,
    type: string
  ) => {
    return axios.get("/api/email_templates", {
      params: {
        region_key: selectedMarket.region_key,
        campaign_id: selectedCampaign.id,
        flow: flowId,
        type: type,
      },
      cancelToken: source.token,
    });
  };

  const loadEmailTemplates = async (source: CancelTokenSource) => {
    try {
      const [
        {
          data: { email_templates: emailTemplates },
        },
      ] = await Promise.all([
        configurableEmailTemplatesRequest(
          source,
          "friend_partial_registration"
        ),
      ]);

      setConfigurableEmailTemplates(emailTemplates);

      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
        setLoading(false);
      }
    }
  };

  useWatch({
    name: [settingsFields.reminderEmail.id],
  });

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadEmailTemplates(source);

    return () => source.cancel();
  }, []);

  const configurableToDropdowItems = (configurables: EmailTemplate[]) =>
    configurables.map((configurable: EmailTemplate) => ({
      label: configurable.name,
      value: configurable.id,
    }));

  const reminderEmailTemplateDropdownItems = configurableToDropdowItems(
    configurableEmailTemplates
  );
  const selectedReminderEmailTemplateDropdownItem =
    reminderEmailTemplateDropdownItems.find(
      (configurableEmailTemplate) =>
        configurableEmailTemplate.value ===
        getValues(settingsFields.reminderEmail.id)
    ) || null;

  useEffect(() => {
    if (!sendReminderEmail) {
      setValue(settingsFields.reminderEmail.id, null);
      setValue(settingsFields.reminderIntervals.id, []);
    }
  }, [sendReminderEmail]);

  useEffect(() => {
    if (!firstReminderInterval) {
      setValue(settingsFields.firstReminderIntervals.id, null);
    }
    if (!secondReminderInterval) {
      setValue(settingsFields.secondReminderIntervals.id, null);
    }
    if (!thirdReminderInterval) {
      setValue(settingsFields.thirdReminderIntervals.id, null);
    }
  }, [firstReminderInterval, secondReminderInterval, thirdReminderInterval]);

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <CaptureAdditionalFriendDetailsEditorWrapper>
      <Accordion label="Partial registration emails" isExpandedByDefault={true}>
        <Fieldset legend={"Reminder email"}>
          <Switch
            id="send_reminder_email"
            isSelected={sendReminderEmail}
            onChange={() => setSendReminderEmail(!sendReminderEmail)}
          >
            Do you want to send a reminder email to the friend some time after
            they partially registered?
          </Switch>
          <Transition showChildren={sendReminderEmail}>
            <div className="space-y-5">
              <Controller
                control={control}
                name={settingsFields.reminderEmail.id}
                render={({ field: { onChange } }) => (
                  <Dropdown
                    id={settingsFields.reminderEmail.id}
                    value={selectedReminderEmailTemplateDropdownItem}
                    items={reminderEmailTemplateDropdownItems}
                    onChange={(e) => onChange((e as OptionTypeBase).value)}
                    label={settingsFields.reminderEmail.label}
                  />
                )}
              />
              <Switch
                id="first_reminder_interval"
                isSelected={firstReminderInterval}
                onChange={() =>
                  setFirstReminderInterval(!firstReminderInterval)
                }
              >
                Set first reminder interval?
              </Switch>
              <Transition showChildren={firstReminderInterval}>
                <Controller
                  control={control}
                  name={settingsFields.firstReminderIntervals.id}
                  defaultValue={0}
                  render={({ field: { onChange } }) => (
                    <TextField
                      elementType="input"
                      type="number"
                      min="0"
                      id={settingsFields.firstReminderIntervals.id}
                      label={settingsFields.firstReminderIntervals.label}
                      aria-label={settingsFields.firstReminderIntervals.label}
                      value={
                        getValues(settingsFields.firstReminderIntervals.id) || 0
                      }
                      onChange={(e) => onChange(parseFloat(e) || 0)}
                    />
                  )}
                />
              </Transition>
              <Switch
                id="second_reminder_interval"
                isSelected={secondReminderInterval}
                onChange={() =>
                  setSecondReminderInterval(!secondReminderInterval)
                }
              >
                Set second reminder interval? (requires first interval)
              </Switch>
              <Transition
                showChildren={firstReminderInterval && secondReminderInterval}
              >
                <Controller
                  control={control}
                  name={settingsFields.secondReminderIntervals.id}
                  defaultValue={0}
                  render={({ field: { onChange } }) => (
                    <TextField
                      elementType="input"
                      type="number"
                      min="0"
                      id={settingsFields.secondReminderIntervals.id}
                      label={settingsFields.secondReminderIntervals.label}
                      aria-label={settingsFields.secondReminderIntervals.label}
                      value={
                        getValues(settingsFields.secondReminderIntervals.id) ||
                        0
                      }
                      onChange={(e) => onChange(parseFloat(e) || 0)}
                    />
                  )}
                />
              </Transition>
              <Switch
                id="third_reminder_interval"
                isSelected={thirdReminderInterval}
                onChange={() =>
                  setThirdReminderInterval(!thirdReminderInterval)
                }
              >
                Set third reminder interval? (requires first and second
                intervals)
              </Switch>
              <Transition
                showChildren={
                  firstReminderInterval &&
                  secondReminderInterval &&
                  thirdReminderInterval
                }
              >
                <Controller
                  control={control}
                  name={settingsFields.thirdReminderIntervals.id}
                  defaultValue={0}
                  render={({ field: { onChange } }) => (
                    <TextField
                      elementType="input"
                      type="number"
                      min="0"
                      id={settingsFields.thirdReminderIntervals.id}
                      label={settingsFields.thirdReminderIntervals.label}
                      aria-label={settingsFields.thirdReminderIntervals.label}
                      value={
                        getValues(settingsFields.thirdReminderIntervals.id) || 0
                      }
                      onChange={(e) => onChange(parseFloat(e) || 0)}
                    />
                  )}
                />
              </Transition>
            </div>
          </Transition>
        </Fieldset>
      </Accordion>
    </CaptureAdditionalFriendDetailsEditorWrapper>
  );
};

export default CaptureAdditionalFriendDetailsSettingsEditor;
