{
  "friend_journey": {
    "capture_friend_details": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true,
            "long": true
          },
          "body": {
            "type": "string",
            "long": true,
            "required": true
          },
          "image": {
            "url": {
              "type": "string",
              "required": true
            },
            "alt_text": {
              "type": "string",
              "required": true,
              "canBeEmpty": true
            },
            "mobile": {
              "key_optional": true,
              "url": {
                "type": "string",
                "required": true
              },
              "alt_text": {
                "type": "string",
                "required": true
              }
            }
          },
          "form": {
            "caption": {
              "type": "string",
              "required": true,
              "long": true
            },
            "button": {
              "text": {
                "type": "string",
                "required": true
              }
            },
            "submit_error": {
              "type": "string",
              "required": true
            },
            "fine_print": {
              "type": "string",
              "required": true,
              "long": true
            },
            "inputs": {
              "full_name": {
                "label": {
                  "type": "string",
                  "required": true
                },
                "placeholder": {
                  "type": "string",
                  "required": true
                },
                "validation_errors": {
                  "type": "validation_error",
                  "required": true
                },
                "tooltip": {
                  "type": "string",
                  "required": false
                }
              },
              "first_name": {
                "label": {
                  "type": "string",
                  "required": true
                },
                "placeholder": {
                  "type": "string",
                  "required": true
                },
                "validation_errors": {
                  "type": "validation_error",
                  "required": true
                },
                "tooltip": {
                  "type": "string",
                  "required": false
                }
              },
              "last_name": {
                "label": {
                  "type": "string",
                  "required": true
                },
                "placeholder": {
                  "type": "string",
                  "required": true
                },
                "validation_errors": {
                  "type": "validation_error",
                  "required": true
                },
                "tooltip": {
                  "type": "string",
                  "required": false
                }
              },
              "email": {
                "label": {
                  "type": "string",
                  "required": true
                },
                "placeholder": {
                  "type": "string",
                  "required": true
                },
                "validation_errors": {
                  "type": "validation_error",
                  "required": true
                },
                "tooltip": {
                  "type": "string",
                  "required": false
                }
              },
              "custom_fields": {
                "type": "custom_fields",
                "required": false,
                "path": "friend_journey.shared.settings.inputs.custom_fields"
              },
              "marketing_opt_in": {
                "label": {
                  "type": "string",
                  "required": true
                },
                "validation_error": {
                  "type": "string",
                  "required": true
                }
              },
              "terms_opt_in": {
                "label": {
                  "type": "string",
                  "required": true
                },
                "validation_error": {
                  "type": "string",
                  "required": true
                }
              }
            }
          },
          "additional_fine_print": {
            "type": "string",
            "required": true,
            "key_optional": true,
            "long": true
          }
        }
      },
      "settings": {
        "inputs": {
          "full_name": {
            "show": {
              "type": "boolean",
              "required": true
            },
            "min_length": {
              "type": "number",
              "required": true
            }
          },
          "first_name": {
            "show": {
              "type": "boolean",
              "required": true
            },
            "min_length": {
              "type": "number",
              "required": true
            }
          },
          "last_name": {
            "show": {
              "type": "boolean",
              "required": true
            },
            "min_length": {
              "type": "number",
              "required": true
            }
          },
          "email": {
            "show": {
              "type": "boolean",
              "required": true
            }
          },
          "marketing_opt_in": {
            "show": {
              "type": "boolean",
              "required": true
            },
            "required": {
              "type": "boolean",
              "required": true
            }
          },
          "terms_opt_in": {
            "show": {
              "type": "boolean",
              "required": true
            }
          }
        },
        "friend_registration_hook": {
          "enabled": {
            "type": "boolean",
            "required": true
          },
          "url": {
            "type": "string",
            "required": true
          }
        },
        "track_referral": {
          "type": "track_referrals",
          "path": "friend_journey.shared.settings.inputs.custom_fields"
        },
        "eligibility_check": {
          "key_optional": true,
          "type": "eligibility_check",
          "required": true
        },
        "button": {
          "show": {
            "type": "boolean",
            "required": true
          }
        },
        "display_partner_rewards": {
          "type": "boolean",
          "required": true
        }
      }
    },
    "successful_friend_registration": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true,
            "long": true
          },
          "body": {
            "type": "string",
            "required": true,
            "long": true
          },
          "buttons": {
            "type": "call_to_actions",
            "required": true
          },
          "secondary_button": {
            "text": {
              "type": "string",
              "required": true,
              "long": true
            }
          }
        }
      },
      "settings": {
        "button": {
          "show": {
            "type": "boolean",
            "required": true
          }
        }
      }
    },
    "continue_to_client_site": {
      "content": {
        "locale": {
          "url": {
            "type": "string",
            "required": true
          },
          "remove_bp_parameters_from_url": {
            "type": "boolean",
            "required": true
          }
        }
      }
    },
    "ineligible_for_voucher": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true,
            "long": true
          },
          "body": {
            "type": "string",
            "required": true,
            "long": true
          },
          "button": {
            "text": {
              "type": "string",
              "required": true
            },
            "url": {
              "type": "string",
              "required": true
            }
          }
        }
      },
      "settings": {
        "button": {
          "show": {
            "type": "boolean",
            "required": true
          }
        }
      }
    },
    "referral_limit_reached": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true,
            "long": true
          },
          "body": {
            "type": "string",
            "required": true,
            "long": true
          },
          "button": {
            "text": {
              "type": "string",
              "required": true
            },
            "url": {
              "type": "string",
              "required": true
            }
          }
        }
      },
      "settings": {
        "button": {
          "show": {
            "type": "boolean",
            "required": true
          }
        }
      }
    },
    "shared": {
      "settings": {
        "inputs": {
          "custom_fields": {
            "type": "custom_fields_settings"
          }
        },
        "voucher_distribution": {
          "type": "voucher_distribution"
        }
      }
    },
    "capture_additional_friend_details": {
      "content": {
        "locale": {
          "first_visit": {
            "instructions": {
              "type": "string",
              "required": true,
              "long": true
            },
            "card": {
              "title": {
                "type": "string",
                "required": true,
                "long": true
              },
              "body": {
                "type": "string",
                "required": true,
                "key_optional": true,
                "long": true
              },
              "fine_print": {
                "type": "string",
                "required": true,
                "key_optional": true,
                "long": true
              }
            }
          },
          "next_visit": {
            "instructions": {
              "type": "string",
              "required": true,
              "key_optional": true,
              "long": true
            },
            "card": {
              "title": {
                "type": "string",
                "required": true,
                "long": true
              },
              "body": {
                "type": "string",
                "required": true,
                "key_optional": true,
                "long": true
              },
              "fine_print": {
                "type": "string",
                "required": true,
                "key_optional": true,
                "long": true
              }
            },
            "show_buttons": {
              "type": "boolean",
              "required": true
            }
          },
          "buttons": {
            "type": "call_to_actions",
            "required": true
          },
          "form": {
            "inputs": {
              "custom_fields": {
                "type": "custom_fields",
                "required": false,
                "tooltips": true,
                "path": "friend_journey.shared.settings.inputs.custom_fields"
              }
            },
            "button": {
              "text": {
                "type": "string",
                "required": true
              }
            },
            "submit_error": {
              "type": "string",
              "required": true
            }
          },
          "fine_print": {
            "type": "string",
            "required": true,
            "key_optional": true,
            "long": true
          }
        }
      },
      "settings": {
        "inputs": {
          "custom_fields": {
            "type": "custom_fields",
            "required": false,
            "tooltips": true
          }
        }
      }
    }
  }
}
