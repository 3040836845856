{
  "friend_journey": {
    "capture_friend_details": {
      "content": {
        "locale": {
          "title": {
            "_label": "Title",
            "_display_conditions": {
              "program_type": "referrer"
            }
          },
          "body": {
            "_label": "Body"
          },
          "image": {
            "_label": "Image",
            "desktop_group": {
              "_label": "Desktop",
              "_paths": [
                "friend_journey.capture_friend_details.content.locale.image.url",
                "friend_journey.capture_friend_details.content.locale.image.alt_text"
              ]
            },
            "url": {
              "_label": "URL",
              "_group": "friend_journey.capture_friend_details.content.locale.image.desktop_group"
            },
            "alt_text": {
              "_label": "Alt text",
              "_group": "friend_journey.capture_friend_details.content.locale.image.desktop_group"
            },
            "mobile": {
              "_label": "Mobile",
              "_toggle": "Show a different image on mobile?",
              "url": {
                "_label": "URL"
              },
              "alt_text": {
                "_label": "Alt text"
              }
            },
            "_display_conditions": {
              "program_type": "referrer"
            }
          },
          "form": {
            "_label": "Form",
            "caption": {
              "_label": "Caption"
            },
            "submit_error": {
              "_label": "Submit error"
            },
            "button": {
              "text": {
                "_label": "Button"
              }
            },
            "fine_print": {
              "_label": "Fine print"
            },
            "inputs": {
              "_label": "Inputs",
              "full_name": {
                "_label": "Full name",
                "label": {
                  "_label": "Label"
                },
                "placeholder": {
                  "_label": "Placeholder"
                },
                "validation_errors": {
                  "_label": "Validation error"
                },
                "tooltip": {
                  "_label": "Tooltip"
                }
              },
              "first_name": {
                "_label": "First name",
                "label": {
                  "_label": "Label"
                },
                "placeholder": {
                  "_label": "Placeholder"
                },
                "validation_errors": {
                  "_label": "Validation error"
                },
                "tooltip": {
                  "_label": "Tooltip"
                }
              },
              "last_name": {
                "_label": "Last name",
                "label": {
                  "_label": "Label"
                },
                "placeholder": {
                  "_label": "Placeholder"
                },
                "validation_errors": {
                  "_label": "Validation error"
                },
                "tooltip": {
                  "_label": "Tooltip"
                }
              },
              "email": {
                "_label": "Email address",
                "label": {
                  "_label": "Label"
                },
                "placeholder": {
                  "_label": "Placeholder"
                },
                "validation_errors": {
                  "_label": "Validation error"
                },
                "tooltip": {
                  "_label": "Tooltip"
                }
              },
              "custom_field": {
                "_label": "Custom field (deprecated)",
                "label": {
                  "_label": "Label"
                },
                "placeholder": {
                  "_label": "Placeholder"
                },
                "validation_errors": {
                  "_label": "Validation error"
                },
                "tooltip": {
                  "_label": "Tooltip"
                }
              },
              "marketing_opt_in": {
                "_label": "Marketing opt-in",
                "label": {
                  "_label": "Label"
                },
                "validation_error": {
                  "_label": "Validation error"
                }
              },
              "terms_opt_in": {
                "_label": "Terms opt-in",
                "label": {
                  "_label": "Label"
                },
                "validation_error": {
                  "_label": "Validation error"
                }
              }
            }
          },
          "additional_fine_print": {
            "_label": "Additional fine print",
            "_toggle": "Show additional fine print at the bottom of the page?"
          }
        }
      },
      "settings": {
        "inputs": {
          "_label": "Inputs",
          "full_name": {
            "_label": "Full name",
            "show": {
              "_label": "Show input field"
            },
            "min_length": {
              "_label": "Minimum length"
            }
          },
          "first_name": {
            "_label": "First name",
            "show": {
              "_label": "Show input field"
            },
            "min_length": {
              "_label": "Minimum length"
            }
          },
          "last_name": {
            "_label": "Last name",
            "show": {
              "_label": "Show input field"
            },
            "min_length": {
              "_label": "Minimum length"
            }
          },
          "email": {
            "_label": "Email address",
            "_group": true,
            "show": {
              "_label": "Show"
            }
          },
          "marketing_opt_in": {
            "_label": "Marketing opt-in",
            "_group": true,
            "show": {
              "_label": "Show toggle field"
            },
            "required": {
              "_label": "Make this opt-in optional",
              "_reverse": true
            }
          },
          "terms_opt_in": {
            "_label": "Terms opt-in",
            "_group": true,
            "show": {
              "_label": "Show toggle field"
            }
          }
        },
        "button": {
          "_label": "Button",
          "show": {
            "_label": "Show button"
          }
        },
        "friend_registration_hook": {
          "_label": "Friend registration hook notification",
          "enabled": {
            "_label": "Notify client when friend successfully registered"
          },
          "url": {
            "_label": "Client endpoint URL",
            "_placeholder": "https://.."
          }
        },
        "track_referral": {
          "_label": "Track a referral on registration or on self reporting",
          "enabled": {
            "_label": "Track a referral when friend successfully registered or self reported"
          },
          "identifier_type": {
            "_label": "What type of unique identifier should be used to identify the conversion?"
          },
          "custom_field_key": {
            "_label": "Which Custom Field should be used to track"
          }
        },
        "eligibility_check": {
          "_toggle": "Would you like to configure an eligibility check?",
          "_label": "Eligibility check"
        },
        "partner_rewards_group": {
          "_label": "Partner rewards",
          "_paths": [
            "friend_journey.capture_friend_details.settings.display_partner_rewards"
          ]
        },
        "display_partner_rewards": {
          "_group": "friend_journey.capture_friend_details.settings.partner_rewards_group",
          "_label": "Display partner rewards?"
        }
      }
    },
    "successful_friend_registration": {
      "content": {
        "locale": {
          "title": {
            "_label": "Title"
          },
          "body": {
            "_label": "Body"
          },
          "buttons": {
            "_label": "Call to actions",
            "_display_options": {
              "bp_toggle": true
            }
          },
          "button": {
            "_label": "Button",
            "text": {
              "_label": "Text"
            },
            "url": {
              "_label": "URL"
            },
            "remove_bp_parameters_from_url": {
              "_label": "Remove BP Parameters from URL"
            }
          },
          "secondary_button": {
            "_label": "Multiple referrals",
            "text": {
              "_label": "Button text"
            }
          }
        }
      },
      "settings": {
        "button": {
          "_label": "Button",
          "show": {
            "_label": "Show button"
          }
        }
      }
    },
    "continue_to_client_site": {
      "content": {
        "locale": {
          "url": {
            "_label": "URL"
          },
          "remove_bp_parameters_from_url": {
            "_label": "Remove BP Parameters from URL"
          }
        }
      }
    },
    "ineligible_for_voucher": {
      "content": {
        "locale": {
          "title": {
            "_label": "Title"
          },
          "body": {
            "_label": "Body"
          },
          "button": {
            "_label": "Button",
            "text": {
              "_label": "Text"
            },
            "url": {
              "_label": "URL"
            }
          }
        }
      },
      "settings": {
        "button": {
          "_label": "Button",
          "show": {
            "_label": "Show button"
          }
        }
      }
    },
    "referral_limit_reached": {
      "content": {
        "locale": {
          "title": {
            "_label": "Title"
          },
          "body": {
            "_label": "Body"
          },
          "button": {
            "_label": "Button",
            "text": {
              "_label": "Text"
            },
            "url": {
              "_label": "URL"
            }
          }
        }
      },
      "settings": {
        "button": {
          "_label": "Button",
          "show": {
            "_label": "Show button"
          }
        }
      }
    },
    "capture_additional_friend_details": {
      "content": {
        "locale": {
          "first_visit": {
            "_label": "First visit",
            "instructions": {
              "_label": "Instructions"
            },
            "card": {
              "_label": "Form wrapper",
              "title": {
                "_label": "Title"
              },
              "body": {
                "_label": "Body",
                "_toggle": "Show body?"
              },
              "fine_print": {
                "_label": "Fine print",
                "_toggle": "Show fine print?"
              }
            }
          },
          "next_visit": {
            "_label": "Next visits",
            "instructions": {
              "_label": "Instructions",
              "_toggle": "Show instructions?"
            },
            "card": {
              "_label": "Form wrapper",
              "title": {
                "_label": "Title"
              },
              "body": {
                "_label": "Body",
                "_toggle": "Show body?"
              },
              "fine_print": {
                "_label": "Fine print",
                "_toggle": "Show fine print?"
              }
            },
            "show_buttons": {
              "_label": "Should the call to actions be displayed on next visits?"
            }
          },
          "buttons": {
            "_label": "Call to actions",
            "_display_options": {
              "bp_toggle": false
            }
          },
          "form": {
            "_label": "Form",
            "inputs": {
              "custom_field": {
                "_label": "Custom field (deprecated)",
                "label": {
                  "_label": "Label"
                },
                "placeholder": {
                  "_label": "Placeholder"
                },
                "validation_errors": {
                  "_label": "Validation error"
                },
                "tooltip": {
                  "_label": "Tooltip"
                }
              }
            },
            "button": {
              "text": {
                "_label": "Button"
              }
            },
            "submit_error": {
              "_label": "Submit error"
            }
          },
          "fine_print": {
            "_label": "Additional fine print",
            "_toggle": "Show additional fine print at the bottom of the page?"
          }
        }
      },
      "settings": {}
    },
    "shared": {
      "content": {},
      "settings": {
        "inputs": {
          "_label": "Form fields",
          "custom_fields": [
            {
              "key": "Date of birth",
              "type": "dob",
              "config": {},
              "position": 1
            }
          ]
        },
        "voucher_distribution": {
          "_label": "Voucher distribution",
          "_display_options": {
            "email_settings": true
          }
        }
      }
    }
  },
  "referrer_journey": {
    "capture_referrer_details": {
      "content": {
        "locale": {
          "title": {
            "_label": "Title"
          },
          "offer_details": {
            "_label": "Offer details",
            "collapsed_title": {
              "_label": "Collapsed button"
            },
            "expanded_title": {
              "_label": "Expanded button"
            },
            "steps[0]": {
              "_label": "First step"
            },
            "steps[1]": {
              "_label": "Second step"
            },
            "steps[2]": {
              "_label": "Third step"
            },
            "footer": {
              "_label": "Footer"
            }
          },
          "form": {
            "_label": "Form",
            "caption": {
              "_label": "Caption"
            },
            "button": {
              "text": {
                "_label": "Button"
              }
            },
            "submit_error": {
              "_label": "Submit error"
            },
            "sign_in_link_emailed_message": {
              "_label": "Already registered - sign in link emailed message"
            },
            "eligibility_check_rejection_message": {
              "_label": "Not eligible to register - eligibility check rejection message"
            },
            "fine_print": {
              "_label": "Fine print"
            }
          },
          "additional_fine_print": {
            "_toggle": "Show additional fine print at the bottom of the page?",
            "_label": "Additional fine print"
          },
          "image": {
            "_label": "Image",
            "desktop_group": {
              "_label": "Desktop",
              "_paths": [
                "referrer_journey.capture_referrer_details.content.locale.image.url",
                "referrer_journey.capture_referrer_details.content.locale.image.alt_text"
              ]
            },
            "url": {
              "_label": "URL",
              "_group": "referrer_journey.capture_referrer_details.content.locale.image.desktop_group"
            },
            "alt_text": {
              "_label": "Alt text",
              "_group": "referrer_journey.capture_referrer_details.content.locale.image.desktop_group"
            },
            "mobile": {
              "_label": "Mobile",
              "_toggle": "Show a different image on mobile?",
              "url": {
                "_label": "URL"
              },
              "alt_text": {
                "_label": "Alt text"
              }
            }
          }
        }
      }
    },
    "shared": {
      "content": {
        "locale": {
          "image": {
            "_label": "Image",
            "url": {
              "_label": "URL"
            },
            "alt_text": {
              "_label": "Alt text"
            }
          },
          "title": {
            "_label": "Title"
          },
          "offer_details": {
            "_label": "Offer details",
            "collapsed_title": {
              "_label": "Collapsed button"
            },
            "expanded_title": {
              "_label": "Expanded button"
            },
            "steps[0]": {
              "_label": "First step"
            },
            "steps[1]": {
              "_label": "Second step"
            },
            "steps[2]": {
              "_label": "Third step"
            },
            "footer": {
              "_label": "Footer"
            }
          },
          "inputs": {
            "_label": "Inputs",
            "full_name": {
              "_label": "Full name",
              "label": {
                "_label": "Label"
              },
              "placeholder": {
                "_label": "Placeholder"
              },
              "validation_errors": {
                "_label": "Validation error"
              },
              "tooltip": {
                "_label": "Tooltip"
              }
            },
            "first_name": {
              "_label": "First name",
              "label": {
                "_label": "Label"
              },
              "placeholder": {
                "_label": "Placeholder"
              },
              "validation_errors": {
                "_label": "Validation error"
              },
              "tooltip": {
                "_label": "Tooltip"
              }
            },
            "last_name": {
              "_label": "Last name",
              "label": {
                "_label": "Label"
              },
              "placeholder": {
                "_label": "Placeholder"
              },
              "validation_errors": {
                "_label": "Validation error"
              },
              "tooltip": {
                "_label": "Tooltip"
              }
            },
            "email": {
              "_label": "Email address",
              "label": {
                "_label": "Label"
              },
              "placeholder": {
                "_label": "Placeholder"
              },
              "validation_errors": {
                "_label": "Validation error"
              },
              "tooltip": {
                "_label": "Tooltip"
              }
            },
            "custom_field": {
              "_label": "Custom field (deprecated)",
              "label": {
                "_label": "Label"
              },
              "placeholder": {
                "_label": "Placeholder"
              },
              "validation_errors": {
                "_label": "Validation error"
              },
              "tooltip": {
                "_label": "Tooltip"
              }
            },
            "marketing_opt_in": {
              "_label": "Marketing opt-in",
              "label": {
                "_label": "Label"
              },
              "validation_error": {
                "_label": "Validation error"
              }
            },
            "terms_opt_in": {
              "_label": "Terms opt-in",
              "label": {
                "_label": "Label"
              },
              "validation_error": {
                "_label": "Validation error"
              }
            }
          }
        }
      },
      "settings": {
        "inputs": {
          "_label": "Inputs",
          "full_name": {
            "_label": "Full name",
            "show": {
              "_label": "Show input field"
            },
            "min_length": {
              "_label": "Minimum length"
            }
          },
          "email": {
            "_label": "Email address",
            "show": {
              "_label": "Show input field"
            }
          },
          "marketing_opt_in": {
            "_label": "Marketing opt-in",
            "show": {
              "_label": "Show field"
            },
            "required": {
              "_label": "Make this opt-in optional",
              "_reverse": true
            }
          },
          "terms_opt_in": {
            "_label": "Terms opt-in",
            "_group": true,
            "show": {
              "_label": "Show field"
            }
          },
          "custom_field": {
            "type": {
              "_label": "Type"
            },
            "key": {
              "_label": "Identifier"
            },
            "config": {
              "number_of_characters": "0"
            }
          },
          "custom_fields": [
            {
              "key": "Date of birth",
              "type": "dob",
              "config": {},
              "position": 1
            }
          ],
          "first_name": {
            "_label": "First name",
            "show": {
              "_label": "Show input field",
              "_deactive": [
                "referrer_journey.shared.settings.inputs.full_name.show"
              ],
              "_activate": [
                "referrer_journey.shared.settings.inputs.last_name.show"
              ]
            },
            "min_length": {
              "_label": "Minimum length"
            }
          },
          "last_name": {
            "_label": "Last name",
            "show": {
              "_label": "Show input field"
            },
            "min_length": {
              "_label": "Minimum length"
            }
          }
        },
        "button": {
          "_label": "Button",
          "show": {
            "_label": "Show button"
          }
        },
        "emails": {
          "_label": "Emails",
          "reminder_email_template_group": {
            "_toggle": "Do you want to send a reminder email to the referrer some time after they registered?",
            "_label": "Reminder email",
            "_paths": [
              "referrer_journey.shared.settings.emails.reminder_email_template_id",
              "referrer_journey.shared.settings.emails.reminder_email_delay_seconds"
            ]
          },
          "reminder_email_template_id": {
            "_toggle": "Do you want to send an email to the referrer when they register?",
            "_group": "referrer_journey.shared.settings.emails.reminder_email_template_group",
            "_label": "Select an email template"
          },
          "reminder_email_delay_seconds": {
            "_toggle": "Do you want to send a reminder email to the referrer some time after they registered?",
            "_label": "How many hours after the registration should the reminder email be sent?",
            "_group": "referrer_journey.shared.settings.emails.reminder_email_template_group",
            "_type": "hours"
          }
        },
        "offer_details": {
          "_label": "Offer details",
          "show_step_icons": {
            "_label": "Show step icons"
          },
          "reveal_on_load": {
            "_label": "Expand offer details section by default (desktop only)"
          }
        },
        "eligibility_check": {
          "_toggle": "Would you like to configure an eligibility check?",
          "_label": "Eligibility check"
        }
      }
    },
    "referrer_dashboard": {
      "content": {
        "locale": {
          "title": {
            "_label": "Title"
          },
          "offer_details": {
            "_label": "Offer details",
            "collapsed_title": {
              "_label": "Collapsed button"
            },
            "expanded_title": {
              "_label": "Expanded button"
            },
            "steps[0]": {
              "_label": "First step"
            },
            "steps[1]": {
              "_label": "Second step"
            },
            "steps[2]": {
              "_label": "Third step"
            },
            "footer": {
              "_label": "Footer"
            }
          },
          "share_message": {
            "_label": "Share message",
            "_tooltip": "This messaging is reused across multiple channels of communication."
          },
          "manual_sharing": {
            "_label": "Share link field",
            "title": {
              "_label": "Label"
            },
            "copy_button_text": {
              "_label": "Copy button"
            },
            "copy_success_notification": {
              "_label": "Successfully copied to clipboard notification"
            }
          },
          "native_sharing": {
            "_label": "Native sharing",
            "title": {
              "_label": "Title (also used as sharing email subject)"
            },
            "button_text": {
              "_label": "Share button"
            }
          },
          "share_code": {
            "_label": "Share code",
            "label": {
              "_label": "Label"
            },
            "copy_button_text": {
              "_label": "Copy button text"
            },
            "copy_success_notification": {
              "_label": "Copy success notification"
            },
            "share_message": {
              "_label": "Share message"
            },
            "native_sharing": {
              "_label": "Native sharing",
              "title": {
                "_label": "Title"
              },
              "button_text": {
                "_label": "Button text"
              }
            }
          },
          "referral_status": {
            "_label": "Referral status",
            "stats": {
              "_label": "Stats",
              "friend_visit_count": {
                "_label": "Friend visit count"
              },
              "friend_registration_count": {
                "_label": "Friend registration count"
              },
              "pending_referral_count": {
                "_label": "Pending referral count"
              },
              "complete_referral_count": {
                "_label": "Complete referral count"
              },
              "cancelled_referral_count": {
                "_label": "Cancelled referral count"
              }
            },
            "pill": {
              "_label": "Pill text",
              "collapsed": {
                "_label": "Pill text when referral status section is collapsed"
              },
              "expanded": {
                "_label": "Pill text when referral status section is expanded"
              }
            },
            "table": {
              "_label": "Table",
              "headers": {
                "_label": "Headers",
                "name": {
                  "_label": "Friend's name header"
                },
                "date": {
                  "_label": "Date header"
                },
                "status": {
                  "_label": "Status header"
                }
              },
              "pending": {
                "_label": "Pending referrals table",
                "title": {
                  "_label": "Title"
                },
                "tooltip": {
                  "_label": "Tooltip"
                }
              },
              "complete": {
                "_label": "Confirmed referrals table",
                "title": {
                  "_label": "Title"
                },
                "tooltip": {
                  "_label": "Tooltip"
                }
              },
              "statuses": {
                "_label": "Statuses",
                "pending": {
                  "_label": "Pending status"
                },
                "complete": {
                  "_label": "Confirmed status"
                }
              }
            }
          },
          "reward_capping": {
            "_label": "Reward capping",
            "warning_title": {
              "_label": "Warning title"
            },
            "volume_warning_message": {
              "_label": "Volume warning message"
            },
            "monetary_warning_message": {
              "_label": "Monetary warning message"
            }
          },
          "logout": {
            "_label": "Logout",
            "message": {
              "_label": "Message"
            },
            "link_text": {
              "_label": "Link text"
            }
          },
          "fine_print": {
            "_label": "Fine print"
          },
          "image": {
            "_label": "Image",
            "desktop_group": {
              "_label": "Desktop",
              "_paths": [
                "referrer_journey.referrer_dashboard.content.locale.image.url",
                "referrer_journey.referrer_dashboard.content.locale.image.alt_text"
              ]
            },
            "url": {
              "_label": "URL",
              "_group": "referrer_journey.referrer_dashboard.content.locale.image.desktop_group"
            },
            "alt_text": {
              "_label": "Alt text",
              "_group": "referrer_journey.referrer_dashboard.content.locale.image.desktop_group"
            },
            "mobile": {
              "_label": "Mobile",
              "_toggle": "Show a different image on mobile?",
              "url": {
                "_label": "URL"
              },
              "alt_text": {
                "_label": "Alt text"
              }
            }
          }
        }
      },
      "settings": {
        "referral_statuses_group": {
          "_label": "Referral Statuses",
          "_paths": [
            "referrer_journey.referrer_dashboard.settings.show_referral_statuses",
            "referrer_journey.referrer_dashboard.settings.show_pending_referrals"
          ]
        },
        "show_referral_statuses": {
          "_label": "Show referral status section?",
          "_group": "referrer_journey.referrer_dashboard.settings.referral_statuses_group"
        },
        "show_pending_referrals": {
          "_label": "Show pending referrals?",
          "_group": "referrer_journey.referrer_dashboard.settings.referral_statuses_group"
        },
        "show_referral_stats": {
          "_label": "Show referral stats?"
        },
        "referral_stats": {
          "_label": "Referral stats",
          "_help": "Select the referral stats in the order you would like them to appear on the dashboard"
        },
        "share_networks": {
          "_label": "Share networks",
          "_help": "Select the share networks in the order you would like them to appear on the dashboard"
        },
        "show_direct_link": {
          "_label": "Show direct link?"
        },
        "show_share_code": {
          "_label": "Show share code?"
        }
      }
    },
    "splash_page": {
      "content": {
        "locale": {
          "title": {
            "_label": "Title"
          },
          "offer_details": {
            "_label": "Offer details",
            "collapsed_title": {
              "_label": "Collapsed button"
            },
            "expanded_title": {
              "_label": "Expanded button"
            },
            "steps[0]": {
              "_label": "First step"
            },
            "steps[1]": {
              "_label": "Second step"
            },
            "steps[2]": {
              "_label": "Third step"
            },
            "footer": {
              "_label": "Footer"
            }
          },
          "form": {
            "_label": "Form",
            "caption": {
              "_label": "Caption"
            },
            "button": {
              "text": {
                "_label": "Button"
              }
            },
            "submit_error": {
              "_label": "Submit error"
            },
            "eligibility_check_rejection_message": {
              "_label": "Not eligible to register - eligibility check rejection message"
            },
            "fine_print": {
              "_label": "Fine print"
            }
          },
          "additional_fine_print": {
            "_toggle": "Show additional fine print at the bottom of the page?",
            "_label": "Additional fine print"
          },
          "image": {
            "_label": "Image",
            "url": {
              "_label": "URL"
            },
            "alt_text": {
              "_label": "Alt text"
            }
          }
        }
      },
      "settings": {
        "layout": "default"
      }
    }
  },
  "click_to_claim_referrer": {
    "fulfilment_expired": {
      "settings": {
        "footer_link": {
          "_label": "Footer link",
          "show": {
            "_label": "Show link"
          }
        }
      }
    },
    "options_selector": {
      "settings": {
        "intro_link": {
          "_label": "Intro link",
          "show": {
            "_label": "Show link"
          }
        }
      }
    },
    "option_confirmation": {
      "settings": {
        "footer_link": {
          "_label": "Footer link",
          "show": {
            "_label": "Show link"
          }
        }
      }
    },
    "reward_emailed": {
      "settings": {
        "footer_link": {
          "_label": "Footer link",
          "show": {
            "_label": "Show link"
          }
        }
      }
    }
  },
  "click_to_claim_friend": {
    "fulfilment_expired": {
      "settings": {
        "footer_link": {
          "_label": "Footer link",
          "show": {
            "_label": "Show link"
          }
        }
      }
    },
    "options_selector": {
      "settings": {
        "intro_link": {
          "_label": "Intro link",
          "show": {
            "_label": "Show link"
          }
        }
      }
    },
    "option_confirmation": {
      "settings": {
        "footer_link": {
          "_label": "Footer link",
          "show": {
            "_label": "Show link"
          }
        }
      }
    },
    "reward_claimed": {
      "settings": {
        "footer_link": {
          "_label": "Footer link",
          "show": {
            "_label": "Show link"
          }
        }
      }
    }
  },
  "user_lookup_journey": {
    "lookup_user": {
      "content": {
        "locale": {
          "title": {
            "_label": "Title"
          },
          "body": {
            "_label": "Body"
          },
          "image": {
            "_label": "Image",
            "url": {
              "_label": "URL"
            },
            "alt_text": {
              "_label": "Alt text"
            }
          },
          "form": {
            "_label": "Form",
            "submit_error": {
              "_label": "Submit error"
            },
            "fine_print": {
              "_label": "Fine print"
            },
            "inputs": {
              "_label": "Inputs",
              "access_code": {
                "_label": "Access code",
                "label": {
                  "_label": "Label"
                },
                "placeholder": {
                  "_label": "Placeholder"
                },
                "validation_errors": {
                  "_label": "Validation error"
                },
                "tooltip": {
                  "_label": "Tooltip"
                }
              }
            },
            "button": {
              "text": {
                "_label": "Button"
              }
            }
          }
        }
      }
    }
  },
  "partner_journey": {
    "shared": {
      "settings": {
        "emails": {
          "_label": "Emails",
          "signed_up_email_template_id": {
            "_label": "Select an email template"
          }
        }
      }
    },
    "partner_dashboard": {
      "content": {
        "locale": {
          "_label": "Title",
          "title": {
            "_label": "Title"
          },
          "referral_stats": {
            "_label": "Referral stats",
            "title": {
              "_label": "Title"
            },
            "friend_visit_count": {
              "_label": "Audience visit count"
            },
            "friend_registration_count": {
              "_label": "Audience registration count"
            },
            "pending_referral_count": {
              "_label": "Pending referral count"
            },
            "complete_referral_count": {
              "_label": "Complete referral count"
            },
            "cancelled_referral_count": {
              "_label": "Cancelled referral count"
            }
          },
          "partner_offer": {
            "_label": "Partner offer",
            "title": {
              "_label": "Title"
            },
            "body": {
              "_label": "Body"
            },
            "image": {
              "_label": "Image",
              "url": {
                "_label": "URL"
              },
              "alt_text": {
                "_label": "Alt text"
              }
            }
          },
          "audience_offer": {
            "_label": "Audience offer",
            "title": {
              "_label": "Title"
            },
            "body": {
              "_label": "Body"
            },
            "image": {
              "url": {
                "_label": "URL"
              },
              "alt_text": {
                "_label": "Alt text"
              }
            }
          },
          "sharing_tools": {
            "_label": "Sharing tools",
            "title": {
              "_label": "Title"
            },
            "direct_link": {
              "_label": "Direct link",
              "label": {
                "_label": "Label"
              },
              "copy_button_text": {
                "_label": "Copy button text"
              },
              "copy_success_notification": {
                "_label": "Copy success notification"
              },
              "share_message": {
                "_label": "Share message"
              },
              "native_sharing": {
                "_label": "Native sharing",
                "title": {
                  "_label": "Title"
                },
                "button_text": {
                  "_label": "Text"
                }
              }
            },
            "share_code": {
              "_label": "Share code",
              "label": {
                "_label": "Label"
              },
              "copy_button_text": {
                "_label": "Copy button text"
              },
              "copy_success_notification": {
                "_label": "Copy success notification"
              },
              "share_message": {
                "_label": "Share message"
              },
              "native_sharing": {
                "_label": "Native sharing",
                "title": {
                  "_label": "Title"
                },
                "button_text": {
                  "_label": "Button text"
                }
              }
            }
          },
          "useful_links": {
            "_label": "Useful links",
            "title": {
              "_label": "Title"
            },
            "buttons": {
              "_array": "Link",
              "text": {
                "_label": "Link text"
              },
              "url": {
                "_label": "URL"
              }
            }
          },
          "performance": {
            "_label": "Performance",
            "title": {
              "_label": "Title"
            },
            "table": {
              "_label": "Performance summary",
              "headers": {
                "_label": "Headers",
                "month": {
                  "_label": "Month"
                },
                "visits": {
                  "_label": "Visits"
                },
                "created": {
                  "_label": "Created"
                },
                "confirmed": {
                  "_label": "Confirmed"
                },
                "cancelled": {
                  "_label": "Cancelled"
                },
                "rewards_confirmed": {
                  "_label": "Rewards confirmed"
                }
              },
              "tooltips": {
                "_label": "Tooltips",
                "visits": {
                  "_label": "Visits"
                },
                "created": {
                  "_label": "Created"
                },
                "confirmed": {
                  "_label": "Confirmed"
                },
                "cancelled": {
                  "_label": "Cancelled"
                }
              },
              "total": {
                "_label": "Total"
              },
              "download_button_text": {
                "_label": "Download button text"
              }
            }
          },
          "rules": {
            "_label": "Rules",
            "title": {
              "_label": "Title"
            },
            "body": {
              "_label": "Body"
            }
          }
        }
      },
      "settings": {
        "audience_offer": {
          "_label": "Audience offer",
          "display_rewards": {
            "_label": "Show audience offer reward list?"
          }
        },
        "partner_offer": {
          "_label": "Partner offer",
          "display_rewards": {
            "_label": "Show partner offer reward list?"
          }
        },
        "show_referral_stats": {
          "_label": "Show referral stats?"
        },
        "referral_stats": {
          "_label": "Referral stats",
          "_help": "Select the referral stats in the order you would like them to appear on the dashboard"
        },
        "sharing_tools": {
          "_label": "Sharing tools",
          "display_direct_link": {
            "_label": "Show the direct link?"
          },
          "display_share_code": {
            "_label": "Show the share code?"
          }
        }
      }
    }
  },
  "wrapper_site": {
    "site": {
      "settings": {
        "font_family": {
          "_label": "Font Family"
        },
        "head_tags": {
          "_label": "Analytics tags",
          "_toggle": "Set Analytics tags?"
        },
        "favicon_img_url": {
          "_label": "Favicon image URL",
          "_toggle": "Show favicon?",
          "_placeholder": "https://example.com/image.png"
        },
        "header": {
          "_label": "Header",
          "show": {
            "_label": "Show?"
          },
          "font_color": {
            "_label": "Font color"
          },
          "background_color": {
            "_label": "Background color"
          },
          "width": {
            "_label": "Width"
          },
          "language_switcher": {
            "show": {
              "_label": "Show language switcher?"
            }
          },
          "logo": {
            "_label": "Logo",
            "img_url": {
              "_label": "Image URL",
              "_toggle": "Show logo?",
              "_placeholder": "https://example.com/image.png"
            },
            "width": {
              "_label": "Width"
            },
            "text_align": {
              "_label": "How do you want the logo aligned?"
            }
          }
        },
        "footer": {
          "_label": "Footer",
          "show": {
            "_label": "Show footer?"
          },
          "font_color": {
            "_label": "Font color"
          },
          "background_color": {
            "_label": "Background color"
          },
          "text_align": {
            "_label": "Text Align"
          },
          "terms_link": {
            "show": {
              "_label": "Show terms link?"
            }
          },
          "privacy_link": {
            "show": {
              "_label": "Show privacy link?"
            }
          }
        }
      },
      "content": {
        "locale": {
          "site": {
            "_label": "Site",
            "title": {
              "_label": "Page title"
            },
            "embed_error": {
              "_label": "Embed error"
            }
          },
          "header": {
            "_label": "Header",
            "logo": {
              "_label": "Logo",
              "url": {
                "_label": "Homepage URL",
                "_toggle": "Set Homepage URL?",
                "_placeholder": "https://example.com"
              },
              "alt_text": {
                "_label": "Alt text",
                "_toggle": "Set alt-text?",
                "_placeholder": "Logo image"
              }
            },
            "language_switcher": {
              "language_name": {
                "_label": "Language name"
              }
            }
          },
          "footer": {
            "_label": "Footer",
            "terms_link_group": {
              "_label": "Terms link"
            },
            "terms_link": {
              "url": {
                "_label": "URL",
                "_toggle": "Set Terms link URL?",
                "_group": "wrapper_site.site.content.locale.footer.terms_link_group",
                "_placeholder": "https://example.com or mailto:email@example.com"
              },
              "text": {
                "_label": "Text",
                "_toggle": "Set Terms link text?",
                "_group": "wrapper_site.site.content.locale.footer.terms_link_group",
                "_placeholder": "Terms & Conditions"
              }
            },
            "privacy_link_group": {
              "_label": "Privacy link"
            },
            "privacy_link": {
              "url": {
                "_label": "URL",
                "_toggle": "Set privacy link URL?",
                "_group": "wrapper_site.site.content.locale.footer.privacy_link_group",
                "_placeholder": "https://example.com or mailto:email@example.com"
              },
              "text": {
                "_label": "Text",
                "_toggle": "Set Privacy link text?",
                "_group": "wrapper_site.site.content.locale.footer.privacy_link_group",
                "_placeholder": "Privacy Policy"
              }
            },
            "contact_link_group": {
              "_label": "Contact link"
            },
            "contact_link": {
              "url": {
                "_label": "URL",
                "_toggle": "Set contact link url?",
                "_group": "wrapper_site.site.content.locale.footer.contact_link_group",
                "_placeholder": "https://example.com or mailto:email@example.com"
              },
              "text": {
                "_label": "Text",
                "_toggle": "Set Contact link text?",
                "_group": "wrapper_site.site.content.locale.footer.contact_link_group",
                "_placeholder": "Contact Us"
              }
            },
            "message": {
              "_label": "Message",
              "_toggle": "Set Footer message?",
              "_placeholder": "&copy; %{current_year}"
            },
            "powered_by_message": {
              "_label": "Powered by message",
              "_toggle": "Set Powered by message?",
              "_placeholder": "Powered by Buyapowa"
            }
          }
        }
      }
    }
  },
  "redirect_friend_to_client_journey": {
    "click_to_redirect_friend": {
      "content": {
        "locale": {
          "title": {
            "_label": "Title"
          },
          "body": {
            "_label": "Body"
          },
          "image": {
            "_label": "Image",
            "url": {
              "_label": "URL"
            },
            "alt_text": {
              "_label": "Alt text"
            }
          },
          "form": {
            "_label": "Form",
            "caption": {
              "_label": "Caption"
            },
            "button": {
              "_label": "Button",
              "text": {
                "_label": "Text"
              },
              "url": {
                "_label": "URL"
              }
            },
            "fine_print": {
              "_label": "Fine print"
            }
          }
        }
      },
      "settings": {
        "voucher_distribution": {
          "_label": "Voucher distribution",
          "_display_options": {
            "email_settings": false
          }
        }
      }
    },
    "automatically_redirect_friend": {
      "content": {
        "locale": {
          "url": {
            "_label": "URL"
          },
          "remove_bp_parameters_from_url": {
            "_label": "Remove BP Parameters from URL"
          }
        }
      },
      "settings": {
        "voucher_distribution": {
          "_label": "Voucher distribution",
          "_display_options": {
            "email_settings": false
          }
        }
      }
    }
  },
  "shared": {
    "settings": {
      "_label": "Shared",
      "fulfilment_hook": {
        "_label": "Fulfilment webhook",
        "enabled": {
          "_label": "Notify client whenever anyone is successfully rewarded",
          "_toggle": "Enable Fulfilment webhook?"
        },
        "url": {
          "_label": "Fulfilment webhook URL"
        }
      }
    }
  }
}
